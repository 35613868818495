<template>
    <div class="" style="height:600px;overflow:scroll">
		<!-- {{list}} -->
		<div class="col-12 lg:col-6 xl:col-12"  v-for="(data,key) in list" :key="key" >
			<!-- <div  > -->
                <br>
			<div class="card mb-0" style="color:white;background-color:#173EA3;font-size:12px;line-height:0.9" >
				<div style="text-align:right">
					<b>Date:</b> {{D_M_Y_formatDate(data.datedmy)}}
					<hr>
				</div>
				<div class="grid">
					<div class="col-2 md:col-2">
						<img style="width:70px;height:70px;  border-radius: 50%;" :src="(data.driver_photo?data.driver_photo:'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o=')">
					</div>
					<div class="grid col-4 md:col-10">
						
						<div class="col-6 md:col-6">
                			<span>status : </span>
								<span style="text-align: right" v-if="data.status == 'Active' || data.status == 'active'">
                        			<badge :value="data.status" severity="success"></badge>
                    			</span>
                    		<span style="text-align: right" v-else-if="data.status == 'InActive' || data.status == 'In Active'">
                        			<badge :value="data.status" severity="warning"></badge>
                   			</span>
						</div><div class="col-6 md:col-6"></div>


						<div class="col-6 md:col-6">
							<span>Driver Name : </span><span style="text-align:right"><b>{{data.driverdetails.first_name.toUpperCase()}} {{data.driverdetails.last_name.toUpperCase()}}</b></span>
						</div>
						<div class="col-6 md:col-6">
							<span>Vehicle Reg No : </span><b>{{data.vehicledetails.registration_number}}</b>
						</div>
							<div class="col-6 md:col-6">
							<span>Driver Mobile Number : </span><b>{{data.driverdetails.mobile_number}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Vehicle Model Name : </span><b>{{data.vehicledetails.model}}</b>
						</div>
                        <div class="col-6 md:col-6">
							<span>Start Point : </span><b>{{data.start_point?data.start_point.location:''}}</b>
						</div>
                        <div class="col-6 md:col-6">
							<span>End Point : </span><b>{{data.end_point?data.end_point.location:''}}</b>
						</div>
                        <div class="col-6 md:col-6">
							<span>Distance : </span><b>{{data.distance?data.distance:''}}</b>
						</div>
                        <div class="col-6 md:col-6">
							<span>Total Time : </span><b>{{data.total_time?data.total_time:''}}</b>
						</div>
                        <div class="col-6 md:col-6">
							<span>Rating : </span><b>{{data.rating?data.rating:''}}</b>
						</div>

					</div>
						
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			<!-- </div> -->
		
		</div>
		
		<div class="col-12" v-if="list==''" style="text-align:left">
            <br>	
			Data Not Found
		</div>
    </div>
</template>

<script>
 import apis from "@/apis";
export default {
    data(){
        return {
            list: [],
        }
    },
props: ['client_id'],
methods:{
	D_M_Y_formatDate(date_parm)
    {
      if(date_parm)
      {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd+"-"+mm + "-" + yyyy ;
        // alert(dd)
        if(dd.toString()!="NaN")
        {
          //this.receipt_new_date = new_date;
          return new_date;
        }
        else
        {
          return date_parm;
        }
      }
    },
    client_map_ride_list() {
        this.isLoadingModel = true;
  
        var data = { client_id: this.client_id,count: false, };
        var promise = apis.rideMaster(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          console.log(response.data.data);
          this.list = response.data.data;
        });
      },
},
mounted(){
	this.client_map_ride_list();
	// alert('here');
}
}
</script>