export default class BreadcrumbService {

    get_home() 
    {  
        
        let home={icon: 'pi pi-home',to: '/'+localStorage.getItem("Breadcrumb_home")};
		return home;
	}
    UserMaster() 
    {   
        let items=  [{label: 'User',to:'/usermaster'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    BrandMaster() 
    {   
        let items=  [{label: 'Brand',to:'/brandmaster'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    ProductMaster() 
    {   
        let items=  [{label: 'Product',to:'/productmaster'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    product_add() 
    {   
        let items=  [{label: 'Product',to:'/productmaster'},
                     {label: 'Product Add',to:'/product_add'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    product_edit() 
    {   
        let items=  [{label: 'Product',to:'/productmaster'},
                     {label: 'Product Edit',to:'/product_edit'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    BannerMaster()
    {
        let items= [{label: 'Banner',to:'/bannermaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    SliderMaster()
    {
        let items= [{label: 'Slider',to:'/slidermaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    ContactMaster()
    {
        let items= [{label: 'Contact',to:'/contact'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    ProductType()
    {
        let items= [{label: 'Product Type',to:'/productType'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    CategoryMaster()
    {
        let items= [{label: 'Category',to:'/category'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    CollectionMaster()
    {
        let items= [{label: 'Collection',to:'/collection'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    TodaysDeal()
    {
        let items= [{label: 'Todays Deals',to:'/todays_deal'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    ECommerceMenu()
    {
        let items= [{label: 'E-commerce Menu',to:'/ecommerce_menu'}]
        let data={
            home:this.get_home(),
            items:items
        }
        return data;
    }
    user_add() 
    {   
        let items=  [{label: 'User',to:'/usermaster'},
                     {label: 'User Add',to:'/user_add'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    user_edit() 
    {   
        let items=  [{label: 'User',to:'/usermaster'},
                     {label: 'User Edit',to:'/user_edit'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    userProfile() 
    {   
        let items=  [{label: 'User',to:'/usermaster'},
                     {label: 'User Profile',to:'/userProfile'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //-------------------------------------------------------------------------------------------------
    client_add() 
    {   
        let items=  [{label: 'Clients',to:'/clientmaster'},
                     {label: 'Client Add/Edit',to:'/client_add'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    clientmaster() 
    {   
        let items=  [{label: 'Clients',to:'/clientmaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    clientProfile() 
    {   
        let items=  [{label: 'Clients',to:'/clientmaster'},
                     {label: 'Client Profile',to:'/clientProfile'},]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //--------------------------------------------------------------------------------------
    ridemaster() 
    {   
        let items=  [{label: 'Rides',to:'/ridemaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    ridedetails() 
    {   
        let items=  [{label: 'Rides',to:'/ridemaster'},
                     {label: 'Ride Details',to:'/ridedetails'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //----------------------------------------------------------------------------------------
    fleetmangermaster() 
    {   
        let items=  [{label: 'Fleet',to:'/fleetmangermaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    fleet_add() 
    {   
        let items=  [{label: 'Fleet',to:'/fleetmangermaster'},
                     {label: 'Fleet Add/Edit',to:'/fleet_add'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    FleetProfile() 
    {   
        let items=  [{label: 'Fleet',to:'/fleetmangermaster'},
                     {label: 'Fleet Profile',to:'/FleetProfile'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //--------------------------------------------------------------------------------
	groupmaster() 
    {   
        let items=  [{label: 'Routes',to:'/groupmaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    groupProfile() 
    {   
        let items=  [{label: 'Routes',to:'/groupmaster'},
                     {label: 'Routes Profile',to:'/groupProfile'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //-----------------------------------------------------------------------------------
	vehiclemaster() 
    {   
        let items=  [{label: 'Vehicles',to:'/vehiclemaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    vehicleprofile() 
    {   
        let items=  [{label: 'Vehicles',to:'/vehiclemaster'},
                     {label: 'Vehicles Profile',to:'/vehicleprofile'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    vehicle_add() 
    {   
        let items=  [{label: 'Vehicles',to:'/vehiclemaster'},
                     {label: 'Vehicles Add',to:'/vehicle_add'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    vehicle_edit() 
    {   
        let items=  [{label: 'Vehicles',to:'/vehiclemaster'},
                     {label: 'Vehicles Edit',to:'/vehicle_edit'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    hire_vehicle_master() 
    {   
        let items=  [{label: 'Hire Vehicles',to:'/hire_vehicle_master'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    hire_vehicle() 
    {   
        let items=  [{label: 'Hire Vehicles',to:'/hire_vehicle_master'},
                     {label: 'Add',to:'/hire_vehicle'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //-----------------------------------------------------------------------------
    client_department() 
    {   
        let items=  [{label: 'Departments',to:'/client_department'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    DepartmentProfile() 
    {   
        let items=  [{label: 'Departments',to:'/client_department'},
                     {label: 'Departments Profile',to:'/DepartmentProfile'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //------------------------------------------------------------------------
    pushnotificationmaster() 
    {   
        let items=  [{label: 'Notification',to:'/pushnotificationmaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //--------------------------------------------------------------------------
    sosalertmaster() 
    {   
        let items=  [{label: 'SOS Alerts',to:'/sosalertmaster'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //-----------------------------------------------------------------------------
    Servicerequest() 
    {   
        let items=  [{label: 'Service Request',to:'/Servicerequest'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    Service() 
    {   
        let items=  [{label: 'Service',to:'/Service'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    serviceProfile() 
    {   
        let items=  [{label: 'Service',to:'/Service'},
                     {label: 'Service Profile',to:'/serviceProfile'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    //-------------------------------------------------------------------------
    rolemaster() 
    {   
        let items=  [{label: 'Roles',to:'/role_master'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    menumaster() 
    {   
        let items=  [{label: 'Menus',to:'/menu_master'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    mediamaster() 
    {   
        let items=  [{label: 'Media',to:'/media_master'}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}
    
    getBreadcrum(label,to) 
    {   
        let items=  [{label: label,to:to}]
        let data={
            home:this.get_home(),
            items:items
        }
		return data;
	}







}